.key-points {
  &__item {
    width: calc-rem(96);
    height: calc-rem(108);
    margin: 0 auto .75rem;
    background-image: url('/assets/front/img/key-point.svg');
    background-size: cover;

    @include responsive(md) {
      width: calc-rem(144);
      height: calc-rem(162);
    }

    svg {
      width: auto;
      height: calc-rem(50);

      @include responsive(md) {
        height: calc-rem(66);
      }
    }
  }
}