.service-card {
  @apply rounded-lg text-center w-full;
  padding: 1rem 1.5rem 2rem;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

  @include responsive(md) {
    padding: 2rem;
  }

  @include responsive(xl) {
    padding: 2.25rem 2rem 2.5rem;
  }

  &__img {
    height: calc-rem(170);
    margin-bottom: .5rem;

    @include responsive(lg) {
      margin-bottom: 2.25rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contains;
    }
  }
}