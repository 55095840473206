.news-card {
  @apply relative w-full flex flex-col;

  &--highlighted {
    @apply relative overflow-hidden;
    background-color: $color__white;
    border-radius: .5rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

    @include responsive(lg) {
      top: calc-rem(36);
      left: 0;
      margin-bottom: calc-rem(-184);
    }

    @include responsive-between(md, lg) {
      max-width: calc-rem(380);
    }

    @include max-responsive(lg) {
      margin: 3rem 0 calc-rem(-280);
    }
  }

  &__tag {
    @apply absolute;
    top: 1rem;
    right: 1rem;
    padding: 2px .25rem;
    color: $color__light-burgundy;
    font-weight: bold;
    font-size: .75rem;
    background-color: $color__light-red;
    border-radius: 2px;
  }

  &__thematic {
    @apply uppercase text-12 md:text-14 font-medium;
    margin-bottom: .5rem;
    color: $color__purple;
    letter-spacing: 1.2px;
  }

  &__pic {
    transition: .4s ease-in-out transform;
  }

  &:hover {
    .news-card__pic {
      transform: scale(1.025);
    }
  }
}

.last-news {
  @include max-responsive(md) {
    @apply relative;
    padding-bottom: 4.5rem;

    &__cta {
      @apply absolute;
      bottom: 0;
      left: 0;
    }
  }
}