.faq {
  &:not(:last-child) {
    margin-bottom: 3rem;

    @include responsive(md) {
      margin-bottom: 5rem;
    }
  }

  &__item {
    border-bottom: 1px solid $color__border;

    &:first-child {
      border-top: 1px solid $color__border;
    }
  }

  &__question {
    padding: 1rem 0;
    color: $color__burgundy;
    font-weight: 500;
    font-size: calc-rem(18);
    line-height: 1.2;
    letter-spacing: -.63px;
    text-align: left;
    transition: .4s ease-in-out color;

    span {
      pointer-events: none;
    }

    @include responsive(md) {
      font-size: 1.25rem;
    }

    @include max-responsive(md) {
      span:last-child {
        position: relative;
        top: -2px;
      }
    }

    @include responsive(xl) {
      &:hover {
        .faq__icon {
          background-color: $color__red;

          &:before,
          &:after {
            background-color: $color__light-red;
          }
        }
      }
    }

    &.is-active {
      color: $color__dark-red;

      .faq__icon:after {
        display: none;
      }
    }
  }

  &__icon {
    @apply relative;
    flex-shrink: 0;
    width: calc-rem(18);
    height: calc-rem(18);
    margin-right: 1rem;
    background-color: $color__light-red;
    border: 2px solid $color__red;
    border-radius: calc-rem(6);
    transition: .4s ease-in-out background-color;

    &:before,
    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: calc-rem(10);
      height: calc-rem(2);
      background-color: $color__red;
      transition: .4s ease-in-out background-color;
      content: '';
    }

    &:before {
      transform: translate(-50%, -50%);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    @include responsive(md) {
      width: 1.5rem;
      height: 1.5rem;
      margin-right: .75rem;
      border-radius: calc-rem(9);

      &:before,
      &:after {
        width: calc-rem(14);
      }
    }
  }

  &__answer {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: .4s ease-in-out height;
  }
}