.hero-need {
  margin-top: -4rem;
  color: $color__white;
  background-image: linear-gradient(297deg, #e5686f 0%, #613db8 100%);

  @include max-responsive(md) {
    margin-bottom: calc((75vw - 3rem) * .75);
  }

  @include responsive(md) {
    margin-top: calc-rem(-112);
  }

  &__pic-wrap {
    pointer-events: none;
    
    @include responsive(md) {
      @apply absolute;
      inset: 0;
    }
  }

  &__pic {
    @apply flex items-end;
    width: 136%;
    min-height: 100%;

    @include max-responsive(md) {
      margin-bottom: -75%;
    }

    @include responsive(md) {
      width: 158%;

      img {
        transform: translateY(15%);
      }
    }
  }

  &__inner {
    padding-top: 4rem;

    @include responsive(md) {
      min-height: calc-rem(680);
      padding: calc-rem(116) 0 4rem;
    }
  }

  &__question {
    @apply absolute;
    z-index: -1;
    width: calc-rem(106);
    max-width: calc-rem(180);
    height: auto;
    transform: translate(-1.5rem, -4rem);

    @include responsive(md) {
      width: 20%;
      transform: translate(-40%, -13%);
    }

    @include responsive(lg) {
      width: 25%;
      transform: translate(-60%, -13%);
    }

    @include responsive(xl) {
      width: 33%;
    }
  }

  &-wrap {
    @include responsive(md) {
      min-height: calc(101vw - 4rem);
    }

    @include responsive(xl) {
      min-height: calc(93vw - 240px);
    }

    @include responsive(xxxl) {
      min-height: calc-rem(1150);
    }
  }
}