.mockups {
  &__desktop {
    @apply absolute;
    top: 5%;
    right: 9.5%;
    z-index: -1;
    width: 74%;
  }

  &__desktop2 {
    @apply absolute;
    top: 5%;
    right: 1%;
    width: 87%;
  }

  &__wrap {
    @include max-responsive(lg) {
      width: 115%;
      margin-top: 2rem;
    }
  }

  &__mobile {
    @apply absolute;
    top: 29.5%;
    left: 1.75%;
    z-index: 0;
    width: 17.2%;
  }

  &__mockup {
    width: 110%;
    max-width: none;
  }
}