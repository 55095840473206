.hero-results {
  @apply relative overflow-hidden;
  z-index: 1;
  margin-top: -4rem;
  padding: 9.75rem 0 3rem; 
  color: $color__white;
  background-color: $color__dark-red;

  @include responsive(md) {
    margin: calc-rem(-112) 0 -6rem;
    padding: 10.5rem 0 11rem;
  }

  &__deco {
    @apply absolute;
    z-index: -1;
    opacity: .75;

    @include max-responsive(lg) {
      width: 100%;

      &--top {
        top: 0;
        left: 0;
        transform: translateX(-50%);
      }

      &--bottom {
        right: 0;
        bottom: 0;
        transform: translateX(50%);
      }
    }

    @include responsive(md) {
      width: 84%;
    }

    @include responsive(lg) {
      width: 75%;
      max-width: calc-rem(1200);

      &--top {
        top: 0;
        left: -25%;
        transform: translateY(-71%);
      }

      &--bottom {
        right: -25%;
        bottom: 0;
        transform: rotate(180deg) translateY(-71%);
      }
    }
  }

  &__contact {
    @apply relative overflow-hidden;
    padding: 5rem 2.5rem 2.25rem;
    background-color: $color__purple;
    border: 2px solid $color__white;
    border-right: none;
    border-radius: 2rem 0 0 2rem;

    @include max-responsive(md) {
      width: calc(100% + 1.5rem);
      margin-right: -1.5rem;
      padding: 4.25rem 1.5rem 2.5rem 2rem;
    }

    @include responsive(xl) {
      padding: 5rem 4rem 2.25rem;
    }

    @include responsive(xxxl) {
      border-right: 1px solid $color__white;
      border-radius: 2rem;
    }

    &-deco {
      @apply absolute;
      top: calc-rem(-50);
      left: -1.5rem;
      transform: rotate(-15deg);

      @include max-responsive(md) {
        transform: rotate(-15deg) scale(.75);
      }
    }

    &-title {
      color: $color__dark-purple;
      font-weight: 600;
      font-size: .75rem;
      letter-spacing: 1.2px;
      text-transform: uppercase;

      @include responsive(md) {
        font-size: calc-rem(15);
        letter-spacing: 2px;
      }
    }
  }

  &__pic {
    @include max-responsive(md) {
      width: calc-rem(70);
      height: calc-rem(70);
    }
  }

  &__button {
    width: calc-rem(58);
    height: calc-rem(46);
    margin: 0 auto calc-rem(10);
    background-color: $color__light-purple;
    border-radius: 1.75rem;
    transition: .4s ease-in-out background-color;

    @include responsive(md) {
      width: calc-rem(70);
      height: 3.5rem;
    }

    a:hover & {
      background-color: $color__white;

      svg {
        fill: $color__light-purple;
      }
    }

    &-wrap {
      display: block;
      width: 4.25rem;
      font-weight: 700;
      font-size: .75rem;
      line-height: 1;
      text-align: center;

      @include responsive(md) {
        width: 5rem;
        font-size: calc-rem(14);
      }
    }
  }
}
