.push {
  @apply flex flex-col justify-between relative overflow-hidden w-full z-1;
  min-height: calc-rem(365);
  border-radius: .5rem;

  @include responsive(md) {
    @apply flex-row;
    min-height: calc-rem(330);
  }

  &--red {
    background-color: $color__dark-red;
  }

  &--burgundy {
    background-color: $color__light-burgundy;

    @include responsive(md) {
      @apply justify-end;
    }
  }

  &__content {
    @apply flex flex-col justify-between flex-grow;
    padding: 2.5rem;
    
    @include responsive(md) {
      &--no-img {
        width: 60%;
      }
    }

    @include max-responsive(md) {
      @apply text-center;
      padding: 1.5rem; 
    }
  }

  &__illu {
    @include max-responsive(md) {
      img {
        width: 100%;
      }
    }

    @include responsive(md) {
      @apply absolute;
      inset: 0;
      z-index: -1;
      flex-shrink: 0;
      pointer-events: none;

      &--left {
        @apply flex justify-end;
      }

      img {
        width: auto;
        height: 100%
      }
    }
  }

  &__pic {
    @apply relative flex-shrink-0;
    flex-shrink: 0;
    -webkit-mask-size: cover;
    mask-size: cover;

    @include responsive(md) {
      width: 40%;
    }

    @include max-responsive(md) {
      padding-top: 60%;

      picture {
        @apply absolute;
        inset: 0;
      }
    }

    &--right {
      -webkit-mask-image: url('/assets/front/img/pushs/push-shape-bottom.svg');
      mask-image: url('/assets/front/img/pushs/push-shape-bottom.svg');

      @include responsive(md) {
        -webkit-mask-image: url('/assets/front/img/pushs/push-shape-right.svg');
        mask-image: url('/assets/front/img/pushs/push-shape-right.svg');
      }
    }

    &--left {
      -webkit-mask-image: url('/assets/front/img/pushs/push-shape-top.svg');
      mask-image: url('/assets/front/img/pushs/push-shape-top.svg');

      @include responsive(md) {
        -webkit-mask-image: url('/assets/front/img/pushs/push-shape-left.svg');
        mask-image: url('/assets/front/img/pushs/push-shape-left.svg');
      }
    }

    img {
      @apply absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.contact-block + .push-block {
  margin-top: 4rem;
  
  @include responsive(md) {
    margin-top: calc-rem(112);
  }
}