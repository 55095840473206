.hero-hp {
  @apply relative flex;
  z-index: 1;
  margin: -4rem 0 48%;
  padding-top: calc-rem(100);

  @include responsive(md) {
    min-height: calc-rem(790);
    margin: calc-rem(-112) 0 12%;
    padding-top: calc-rem(184);
  }

  @include responsive(xxxl) {
    margin-bottom: calc-rem(185);
  }

  &:before,
  &:after {
    @apply absolute;
    top: 0;
    left: 0;
    opacity: .7;
    content: '';
  }

  &:before {
    right: 0;
    height: calc-rem(200);
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  }

  &:after {
    bottom: 0;
    width: 100%;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);

    @include max-responsive(md) {
      opacity: .8;
    }

    @include responsive(md) {
      width: 50%;
    }
  }

  &__bg {
    @apply absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    max-width: calc-rem(690);
  }

  &__deco {
    @apply absolute;
    z-index: 2;

    &--cursor {
      top: 32%;
      left: -6%;
      width: 13.5%;
    }

    &--tab {
      top: -8.75%;
      right: -27%;
      width: 60.5%;
    }

    &--chat {
      top: -3%;
      left: 20%;
      width: 24.5%;
    }

    &--check {
      top: 15%;
      right: -22%;
      width: 23%;

      @include max-responsive(md) {
        display: none;
      }
    }
  }

  &__secondary {
    pointer-events: none;
    
    @include max-responsive(md) {
      margin: 3rem 0 -54%;
    }
    
    @include responsive(md) {
      @apply absolute w-full;
      bottom: 0;
      left: 0;
      transform: translateY(30%);
    }

    &-inner {
      @apply relative;
      padding: calc-rem(10);
    }
  }

  &__shape {
    @apply absolute;
    top: 0;
    left: 0;
  }
}
