.custom-select {
  &-container {
    position: relative;
    width: 100%;

    select {
      width: 100%;
      padding-bottom: .75rem;
      background-color: transparent;
      border-bottom: 1px solid $color__white;
      border-radius: 0;
      appearance: none;

      @include responsive(lg) {
        @include sr-only();
      }

      option {
        width: 100%;
      }
    }

    &.is-open {
      .custom-select-panel {
        opacity: 1;
        pointer-events: auto;
      }
  
      .custom-select-icon-wrapper svg {
        transform: scaleY(-1);
      }
    }
  }

  &-icon-wrapper {
    position: absolute;
    top: 5px;
    right: 0;
    pointer-events: none;
  }

  &-opener {
    display: none;
    width: 100%;
    padding-bottom: .75rem;
    font-size: calc-rem(18);
    letter-spacing: -.56px;
    border-bottom: 1px solid $color__white;
    cursor: pointer;

    @include responsive(lg) {
      display: block;
    }
  }

  &-panel {
    position: absolute;
    top: 2.5rem;
    left: 0;
    z-index: 10;
    width: 100%;
    overflow: auto;
    background-color: $color__purple;
    border: 1px solid $color__white;
    border-top: none;
    border-radius: 0 0 .5rem .5rem;
    opacity: 0;
    transition: opacity .4s ease-in-out;
    pointer-events: none;
  }

  &-option {
    padding: .5rem 1rem;
    font-size: calc-rem(18);
    letter-spacing: -.56px;
    cursor: pointer;
    transition: .4s ease-in-out color;

    &.has-focus {
      color: $color__dark-purple;
    }

    &.is-selected {
      color: $color__dark-purple;
    }
  }
}

// for sorting select
.is-sort {
  .custom-select {
    &-container {
      flex-shrink: 0;
      width: auto;

      &.is-open {
        .custom-select-opener {
          border-bottom: none;
          border-radius: calc-rem(28) calc-rem(28) 0 0;
        }
      }

      select {
        @apply uppercase;
        width: calc-rem(120);
        height: 2.5rem;
        padding: 0 1rem;
        color: $color__burgundy;
        font-weight: bold;
        font-size: calc-rem(11);
        line-height: 1;
        letter-spacing: 1px;
        border: 2px solid $color__burgundy;
        border-radius: 1.25rem;
      }
    }

    &-opener {
      @apply uppercase text-burgundy;
      height: 3rem;
      padding: 1rem calc-rem(48) 1rem 2rem;
      font-weight: bold;
      font-size: .75rem;
      line-height: 1;
      letter-spacing: 1px;
      border: 2px solid $color__burgundy;
      border-radius: calc-rem(28);
    }

    &-icon-wrapper {
      top: calc-rem(7);
      right: .75rem;

      @include responsive(lg) {
        top: calc-rem(11);
        right: 1rem;
      } 

      path {
        stroke: $color__burgundy;
      }
    }

    &-option {
      @apply uppercase text-burgundy;
      font-weight: bold;
      font-size: .75rem;
      line-height: 1;
      letter-spacing: 1px;
      white-space: nowrap;

      &.has-focus,
      &.is-selected {
        color: $color__dark-red;
      }

      &:first-child {
        display: none;
      }
    }

    &-panel {
      top: 3rem;
      padding-bottom: 1rem;
      background-color: $color__white;
      border: 2px solid $color__burgundy;
      border-top: none;
      border-radius: 0 0 calc-rem(28) calc-rem(28);
    }
  }
}