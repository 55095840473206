.pagination {
  @apply flex justify-center;
  padding-top: 1rem;

  &__item:not(:last-child) {
    margin-right: .5rem;

    @include responsive(md) {
      margin-right: calc-rem(10);
    }
  }

  &__link {
    width: calc-rem(38);
    height: calc-rem(38);
    color: $color__burgundy;
    font-weight: 600;
    border: 1px solid $color__border;
    border-radius: 50%;
    transition: .4s ease-in-out border, .4s ease-in-out background-color, .4s ease-in-out color;

    @include responsive(md) {
      width: 3rem;
      height: 3rem;
      font-size: calc-rem(18);
    }

    &.is-active,
    &:hover {
      color: $color__white;
      background-color: $color__burgundy;
      border-color: $color__burgundy;

      svg {
        fill: $color__white;
      }
    }

    &.is-disabled {
      opacity: .5;
      pointer-events: none;

      svg {
        fill: $color__border;
      }
    }

    svg {
      @include max-responsive(md) {
        width: .5rem;
        height: auto;
      }
    }

    &--prev {
      transform: scaleX(-1);
    }
  }
}