.favorite-article {
  @apply relative z-1 text-center;
  padding: 3.5rem 0;

  &:before {
    @apply absolute;
    top: 0;
    right: -50vw;
    bottom: 0;
    left: -50vw;
    z-index: -1;
    background-color: $color__lighter-grey;
    content: '';
  }
}