.partners {
  .swiper-wrapper {
    @include responsive(md) {
      flex-wrap: wrap;
    }
  }

  &__item {
    padding: 1.5rem 1rem;
    border-radius: .25rem;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);

    @include responsive(md) {
      margin-bottom: 2rem;
      padding: 2rem 1rem;
    }

    @include responsive-between(md,lg) {
      width: calc(50% - .75rem)!important;

      &:not(:nth-child(2n + 2)) {
        margin-right: 1.5rem;
      }
    }

    @include responsive-between(lg,xxl) {
      width: calc(25% - 1.5rem)!important;

      &:not(:nth-child(4n + 4)) {
        margin-right: 1.5rem;
      }
    }

    @include responsive(xxl) {
      width: calc(16.66% - 1.25rem)!important;

      &:not(:nth-child(6n + 6)) {
        margin-right: 1.5rem;
      }
    }
  }

  &__img {
    @apply flex items-center justify-center;
    height: calc-rem(105);
  }
}