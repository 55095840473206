.slice {
  &:not(:last-child) {
    margin-bottom: 4rem;
    
    @include responsive(md) {
      margin-bottom: calc-rem(112);
    }
  }
}

.slices-wrapper {
  padding-bottom: 3rem;

  @include responsive(md) {
    padding-bottom: calc-rem(96);
  }
}