.video {
  &__overlay {
    @apply absolute flex items-end;
    inset: 0;
    padding: 2rem 1.5rem;
    color: $color__white;
    background-position: center;
    background-size: cover;
    transition: .4s visibility ease-in-out, .4s opacity ease-in-out;

    @include responsive(md) {
      padding: 3rem calc-rem(36);
    }

    &.is-hidden,
    .lyt-activated & {
      visibility: hidden;
      opacity: 0;
    }
    

    &:hover {
      cursor: pointer;

      .video__play {
        background-color: $color__dark-red;
      }
    }

    &:before {
      position: absolute;
      inset: 0;
      background-image: linear-gradient(to top, rgba(14, 14, 14, 0.5), rgba(43, 43, 43, 0));
      content: '';
    }
  }

  &__play {
    width: 3rem;
    height: 3rem;
    background-color: $color__red;
    border-radius: 50%;
    transition: .4s background-color ease-in-out;

    @include responsive(md) {
      width: 4rem;
      height: 4rem;
    }

    @include max-responsive(md) {
      svg {
        width: calc-rem(17);
        height: auto;
      }
    }

    &:hover {
      background-color: $color__dark-red;
    }
  }
}

/* stylelint-disable-next-line */
lite-youtube {
  @apply flex items-center;
  max-width: none;
  // border-radius: calc-rem(10);

  &:before {
    display: none;
  }
}