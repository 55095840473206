.reviews {
  &__left {
    padding: 2.5rem 1rem;
    text-align: center;
    background-color: $color__dark-red;
    border-radius: .5rem;

    @include responsive(lg) {
      padding: 4rem 2rem;
    }
  }

  &__wrapper {
    margin-top: -2.75rem;
    margin-left: -1.5rem;
    padding: 2.75rem 0 2rem 1.5rem;

    &--quote {
      padding-bottom: 0;
    }
  }

  &__icon {
    position: absolute;
    bottom: -2.5rem;
    left: 50%;
    transform: translateX(-50%);
  }

  &__global {
    position: relative;
    width: calc-rem(142);
    height: calc-rem(160);
    margin: 1.25rem auto calc-rem(56);
    background-color: $color__white;

    &-inner {
      width: calc-rem(126);
      height: calc-rem(140);
      font-size: 1.25rem;
      background-color: $color__red;

      span {
        display: block;
        font-weight: 600;
        font-size: 3rem;
        line-height: 1;
        letter-spacing: -1.5px;
      }
    }
  }
}

.review {
  @apply relative;

  &__txt {
    color: $color__dark-red;
    font-size: calc-rem(18);
    line-height: 1.55;

    &--purple {
      color: $color__purple2;
    }

    @include responsive(md) {
      font-size: calc-rem(22);
    }
  }

  &__icon {
    position: absolute;
    top: -1.5rem;
    left: -1rem;
    z-index: -1;

    @include max-responsive(lg) {
      width: 3rem;
      height: 2.5rem;
    }

    @include responsive(lg) {
      top: -2.75rem;
      left: -1.5rem;
    }
  }

  &__badge {
    position: absolute;
    bottom: -1rem;
    left: 50%;
    width: calc-rem(36);
    height: 2.5rem;
    background-color: $color__white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);

    @include responsive(md) {
      bottom: calc-rem(-30);
      width: 3rem;
      height: calc-rem(52);
    }

    &--no-img {
      position: static;
      transform: none;
    }

    &-inner {
      width: calc-rem(30);
      height: calc-rem(34);
      color: $color__white;
      font-weight: 600;
      font-size: calc-rem(18);
      background-color: $color__red;

      @include responsive(md) {
        width: calc-rem(39);
        height: calc-rem(44);
        font-size: calc-rem(22);
      }
    }
  }

  &__img {
    border-radius: 50%;

    @include max-responsive(md) {
      width: calc-rem(66);
      height: calc-rem(66);
    }
  }
}