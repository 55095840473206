.contact {
  @apply relative;
  padding-bottom: 4.5rem;

  &__icon {
    @include max-responsive(md) {
      width: calc-rem(52);
      height: auto;
    }
  }

  &__deco {
    @apply absolute;
    z-index: -1;
    opacity: .35;
    pointer-events: none;

    &--1 {
      top: 0;
      left: 1.5rem;
      transform: rotate(-30deg);

      @include max-responsive(md) {
        left: -1rem;
        width: calc-rem(56);
        height: 3rem;
      }
    }

    &--2 {
      top: calc-rem(250);
      left: calc-rem(-120);
      transform: rotate(30deg) scaleX(-1);

      @include max-responsive(md) {
        top: calc-rem(160);
        left: -2.5rem;
        width: calc-rem(56);
        height: 3rem;
      }
    }

    &--3 {
      top: -2rem;
      right: calc-rem(-195);
      transform: rotate(30deg) scaleX(-1);

      @include max-responsive(md) {
        top: -2.5rem;
        right: -3.5rem;
        width: calc-rem(135);
        height: auto;
      }
    }

    &--4 {
      top: calc-rem(416);
      left: 8%;
      transform: rotate(30deg) scaleX(-1);
      filter: blur(4px);

      @include max-responsive(md) {
        top: calc-rem(340);
        width: calc-rem(52);
        height: auto;
        filter: blur(2px);
      }
    }

    &--5 {
      top: calc-rem(416);
      right: 0;
      transform: rotate(-30deg);
      filter: blur(4px);

      @include max-responsive(md) {
        top: calc-rem(375);
        width: calc-rem(37);
        height: auto;
        filter: blur(2px);
      }
    }
  }
  
  &__cta {
    @apply inline-flex items-center;
    height: calc-rem(52);
    padding: 0 calc-rem(24);
    font-weight: 600;
    font-size: calc-rem(20);
    background-color: #613db8;
    border-radius: calc-rem(28);
    transition: .4s ease-in-out color, .4s ease-in-out background-color;

    @include responsive(md) {
      height: calc-rem(58);
      padding: 0 calc-rem(28);
      font-size: 1.5rem;
    }

    &:hover {
      color: #613db8;
      background-color: $color__white;

      svg {
        fill: #613db8;
      }
    }
  }

  &__infos {
    padding: 2rem 1.25rem;
    border: 1px solid $color__white;
    border-radius: .5rem;

    @include responsive(md) {
      padding: 2.5rem;
    }
  }

  &__legals {
    font-size: calc-rem(13);
    line-height: 1.57;
    letter-spacing: -.44px;

    @include responsive(md) {
      font-size: calc-rem(14);
    }
  }
}