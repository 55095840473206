.form {
  &__item {
    @apply relative;
    width: 100%;
    margin-bottom: 1.5rem;

    &.has-error {
      &:before,
      &:after {
        @apply absolute;
        content: '';
      }

      &:before {
        right: 0;
        bottom: .75rem;
        width: calc-rem(22);
        height: calc-rem(22);
        background-color: $color__white;
        border-radius: 50%;
      }

      &:after {
        right: calc-rem(6);
        bottom: calc-rem(18);
        width: 9px;
        height: 9px;
        background-image: url('/assets/front/img/error.svg');
      }
    }

    @include responsive(md) {
      margin-bottom: 3rem;

      &--half {
        width: calc(50% - .75rem);
      }
    }
  }

  &__label {
    @apply uppercase mb-2 block;
    color: $color__dark-purple;
    font-weight: bold;
    font-size: calc-rem(11);
  }

  &__input {
    @apply w-full;
    padding-bottom: .72rem;
    font-size: calc-rem(18);
    letter-spacing: -.56px;
    background-color: transparent;
    border-bottom: 1px solid rgba($color__white, .5);

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: #a37eff;
      opacity: 1;
    }
  }

  &__field-error {
    @apply absolute;
    bottom: -6px;
    left: 0;
    color: $color__dark-purple;
    letter-spacing: -.5px;
    transform: translateY(100%);

    &--global {
      position: static;
      margin-top: 1rem;
    }
  }
}