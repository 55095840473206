.tool-card {
  @apply bg-lighter-grey rounded-lg text-center w-full;
  padding: 1rem 1.5rem 2rem;

  @include responsive(md) {
    padding-top: 2rem;
  }

  @include responsive(lg) {
    padding: 2rem 0 2.5rem;
  }

  &__img {
    height: calc-rem(175);
    margin-bottom: .5rem;

    @include responsive(lg) {
      height: calc-rem(270);
      margin-bottom: 2rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}