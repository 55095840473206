.hero-edito {
  @apply relative;
  z-index: 1;
  margin: -4rem 0 4rem;
  color: $color__white;
  background-color: $color__dark-red;

  &--dark {
    background-color: $color__light-burgundy;
  }

  &--image {
    @include max-responsive(md) {
      margin-bottom: 65vw;

      &.hero-edito--dark {
        margin-bottom: 57vw;
      }
    }

    &.has-no-chapo {
      @include responsive(md) {
        margin-bottom: calc((100vw - 4rem) * 0.25);
      }

      @include responsive(lg) {
        margin-bottom: calc((100vw - 4rem) * 0.35);
      }

      @include responsive(xl) {
        margin-bottom: calc(50vw - 240px);
      }
    }
  }

  .breadcrumb {
    margin-top: 0;
  }

  @include responsive(md) {
    margin: calc-rem(-112) 0 0;
  }

  &-wrap {
    @include responsive(md) {
      min-height: calc((100vw - 4rem) * .85);
    }

    @include responsive(lg) {
      min-height: calc((100vw - 4rem) * .825);
    }

    @include responsive(xl) {
      min-height: calc((100vw - 240px) * .825);
    }

    @include responsive(xxxl) {
      min-height: calc-rem(1110);
    }
  }

  &__inner {
    @apply flex flex-col;
    min-height: calc-rem(360);
    padding: 5rem 0 4rem;

    @include max-responsive(md) {
      &--agenda {
        padding-bottom: 0;
      }

      &--image-dark {
        padding-bottom: calc((100vw - 3rem) * .5);
      }

      &--image-light {
        padding-bottom: calc((100vw - 3rem) * .35);
      }
    }

    @include responsive(md) {
      min-height: calc-rem(400);
      padding: calc-rem(136) 0 5rem;
    }
  }

  &__illu-wrap {
    @apply absolute;
    inset: 0;
    z-index: -1;
    pointer-events: none;
  }

  &__illu-inner {
    @apply absolute overflow-hidden;
    inset: 0;
    
    img {
      @include max-responsive(lg) {
        @apply absolute;
        bottom: 0;
        left: 0;
        transform: translateY(45%);
      }

      @include responsive(lg) {
        margin-top: -14%;
      }
    }

    &--dark {
      img {
        @include responsive(md) {
          margin-top: 2.5%;
          margin-left: -5%;
        }

        @include max-responsive(lg) {
          @apply absolute;
          bottom: 0;
          left: 0;
          transform: translateY(38%);
        }

        @include max-responsive(md) {
          transform: translateY(58%);
        }
      }

      &.hero-edito__illu-inner--image {
        img {
          @include responsive(md) {
            margin-top: -1%;
          }
        }
      }
    }
    
    &--image {
      img {
        @include max-responsive(md) {
          transform: translateY(60%);
        }
      }
    }
  }

  &__illu {
    @apply relative;
    width: 150%;
    height: 100%;

    @include responsive(md) {
      width: 155%;
    }

    &--dark {
      width: 132%;

      @include responsive(md) {
        width: 163%;
      }
    }
  }

  &__img {
    position: absolute;
    bottom: 0;
    left: 22%;
    z-index: 1;
    width: 56.5%;
    transform: translateY(67%);

    @include responsive(md) {
      transform: translateY(41%);
    }

    @include responsive(lg) {
      top: 0;
      bottom: auto;
      transform: translateY(16%);
    }
    
    &--dark {
      left: 14%;
      width: 69%;
      transform: translateY(60%);

      @include responsive(md) {
        left: 9%;
        width: 71%;
        transform: translateY(31%);
      }

      @include responsive(lg) {
        left: 8%;
        width: 74%;
        transform: translateY(17%);
      }
    }
  }

  &__date {
    @apply flex items-center tracking-tight;
    margin-top: 1rem;
    color: $color__border;
    font-weight: 500;
    font-size: calc-rem(14);

    @include responsive(md) {
      font-size: calc-rem(18);
    }

    @include max-responsive(md) {
      svg {
        width: 1rem;
        height: auto;
      }
    }
  }

  &__logo {
    @apply relative overflow-hidden;
    width: 3rem;
    height: 3rem;
    background-color: $color__white;
    border-radius: calc-rem(6);

    img {
      width: 2.5rem;
      height: 2.5rem;
      object-fit: contain;
    }
  }

  &__thematic {
    @apply uppercase;
    margin-bottom: 1.25rem;
    color: $color__light-red;
    font-weight: 600;
    font-size: calc-rem(14);
    letter-spacing: 1.75px;
  }
}