.key-figures {
  &__item {
    width: 50%;
    margin-bottom: 2rem;
    padding: 0 calc-rem(10);

    @include responsive-between(md,lg) {
      &:not(:nth-child(2n + 2)):not(:last-child) {
        border-right: 1px solid $color__border;
      }
    }

    @include responsive(md) {
      padding: 0 2.5rem;
    }

    @include responsive(lg) {
      width: 25%;
      
      &:not(:nth-child(4n + 4)):not(:last-child) {
        border-right: 1px solid $color__border;
      }
    }

    @include responsive(xl) {
      padding: 0 calc-rem(60);
    }
  }

  &__items {
    margin: 1.5rem calc-rem(-10) -1.5rem;

    @include responsive(md) {
      margin: 3rem -2.5rem -2rem;
    }

    @include responsive(xl) {
      margin: 3rem calc-rem(-60) -2rem;
    }
  }

  &__caption {
    color: $color__burgundy;
    font-weight: 500;
    font-size: calc-rem(15);
    line-height: 1.56;
    letter-spacing: -.45px;

    @include responsive(md) {
      font-size: calc-rem(18);
    }
  }

  &__number {
    color: $color__red;
    font-weight: 600;
    font-size: calc-rem(56);
    line-height: 1;
    letter-spacing: -2px;
    word-break: break-all;

    span {
      font-size: 2rem;
    }

    @include responsive(md) {
      font-size: calc-rem(70);

      span {
        font-size: 2.5rem;
      }
    }
  }
}