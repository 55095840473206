.img-text {
  &__img {
    @include responsive(md) {
      border-radius: .5rem 0 0 .5rem;

      &--left {
        border-radius: 0 .5rem .5rem 0;
      }
    }

    @include responsive(xxxl) {
      border-radius: .5rem;
    }
  }
}