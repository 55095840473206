.solution-card {
  @apply flex items-center;
  padding: .75rem 2rem;
  background-color: $color__white;
  border-radius: .5rem;
  box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.1);

  &__bullets {
    font-weight: 500;
    font-size: calc-rem(18);
    letter-spacing: -1.2px;

    li:before {
      border: 2px solid $color__purple2;
    }
  }

  &__illu {
    @apply relative;
    width: 100%;
    height: calc-rem(220);

    img {
      @apply absolute;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.solution-block {
  padding: 3rem 1.5rem 3.5rem;

  @include responsive(md) {
    padding: 3.75rem 2rem 5rem;
  }

  @include responsive(lg) {
    padding-right: 0;
    padding-left: 0;
  }

  &__plus {
    @include max-responsive(md) {
      width: calc-rem(38);
      height: auto;
    }
  }
}