.teams {
  .swiper-wrapper {
    @include responsive(md) {
      flex-wrap: wrap;
    }
  }

  &__item {
    @include responsive(md) {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }

    @include responsive-between(md, lg) {
      width: calc(50% - .75rem)!important;

      &:not(:nth-child(2n + 2)) {
        margin-right: 1.5rem;
      }
    }

    @include responsive(lg) {
      width: calc(25% - 1.5rem)!important;

      &:not(:nth-child(4n + 4)) {
        margin-right: 1.5rem;
      }
    }
  }
}