.event-card {
  @apply relative overflow-hidden w-full flex;
  padding: 1.5rem 1.5rem 2rem;
  background-color: #fcf2f5;
  border-radius: .5rem;

  &--past {
    background-color: $color__lighter-grey;
  }

  @include responsive(md) {
    padding: 2rem;
  }

  &--highlighted {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2);

    @include max-responsive(lg) {
      margin: calc-rem(56) 0 calc-rem(-190);
    }

    @include responsive(lg) {
      @apply absolute;
      right: 0;
      bottom: -10rem;
      left: 0;
    }
  }

  &__desc {
    line-height: 1.63;
  }

  &__schedule {
    @apply absolute;
    top: 1.5rem;
    right: 1.25rem;
    width: calc(100% - 172px);

    @include responsive(md) {
      top: 2rem;
      right: 2rem;
    }
  }

  &__date {
    @apply absolute z-1 text-center text-white;
    top: calc-rem(11);
    left: calc-rem(10);
    font-weight: 600;
    font-size: calc-rem(14);
    line-height: 1;
    letter-spacing: -.025em;

    span {
      display: block;
      margin-bottom: calc-rem(2);
      font-size: calc-rem(26);
    }

    svg {
      @apply absolute;
      top: calc-rem(-115);
      left: calc-rem(-80);
      z-index: -1;
      max-width: none;

      @include max-responsive(md) {
        top: calc-rem(-82);
        left: calc-rem(-56);
        width: calc-rem(160);
        height: auto;
      }
    }

    @include responsive(md) {
      top: calc-rem(15);
      left: 1rem;
      font-size: calc-rem(18);

      span {
        font-size: 2rem;
      }
    }
  }

  &__content {
    @apply w-full flex flex-col items-start justify-between;
    padding-top: calc-rem(68);

    @include responsive(md) {
      padding-top: calc-rem(82);
    }
  }
}