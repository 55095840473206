.tools-services {
  @apply relative;
  
  @include responsive(lg) {
    &:after {
      @apply absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      border-right: 1px solid $color__border;
      content: '';
    }
  }
}