.cs-card {
  @apply w-full flex flex-col justify-between items-start text-white bg-dark-red relative overflow-hidden rounded-lg;
  min-height: calc-rem(385);
  padding: 1rem 1.25rem 1.5rem;

  @include responsive(md) {
    min-height: calc-rem(478);
    padding: 1.5rem 1.5rem 2rem;
  }

  &__deco {
    @apply absolute;
    right: -10rem;
    bottom: -16rem;
    width: calc-rem(400);
    height: auto;
  }

  &__logo {
    @apply relative overflow-hidden;
    flex-shrink: 0;
    width: 3rem;
    height: 3rem;
    background-color: $color__white;
    border-radius: .5rem;

    @include responsive(md) {
      width: 5rem;
      height: 5rem;
    }

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }

  &__context {
    font-weight: 300;
    line-height: 1.6;

    @include max-responsive(md) {
      font-size: calc-rem(15);
      line-height: 1.4;
    }
  }
}