.report-card {
  @apply text-white bg-light-burgundy flex flex-col justify-between items-start relative w-full rounded-lg overflow-hidden;
  min-height: calc-rem(368);
  padding: 1.5rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  @include responsive(md) {
    min-height: calc-rem(452);
    padding: 2rem;
  }

  &__date {
    @apply flex items-center mb-4 text-13 md:text-15;
    color: $color__border;
  }

  &__deco {
    @apply absolute;
    right: -7rem;
    bottom: -11rem;
    pointer-events: none;
  }
}