.pricing-card {
  @apply w-full flex flex-col justify-between items-start;
  padding: 2rem;
  color: $color__white;
  font-weight: 500;
  font-size: calc-rem(15);
  line-height: 1.6;
  letter-spacing: -.8px;
  border-radius: .5rem;

  @include responsive(md) {
    padding: 2.5rem;
    font-size: calc-rem(18);
    line-height: 1.33;
  }

  &__title {
    span {
      color: $color__white;
      background-image: linear-gradient(currentColor, currentColor);
      background-repeat: no-repeat;
      background-position: 0% 90%;
      background-size: 100% 1px;
    }
  }

  &__price {
    font-weight: bold;
    font-size: calc-rem(44);
    line-height: 1;
    letter-spacing: -.025em;

    @include responsive(md) {
      font-size: calc-rem(56);
    }
  }
}